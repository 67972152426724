var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import HeaderLogo from '../assets/header/footer_mobile.svg';
import item1 from '../assets/image/Footer/twitter_normal@2x.png';
import item2 from '../assets/image/Footer/medium_normal@2x.png';
import item3 from '../assets/image/Footer/telegram_normal@2x.png';
import item5 from '../assets/image/Footer/gitbook_normal@2x.png';
import item6 from '../assets/image/Footer/github_normal@2x.png';
import item7 from '../assets/image/Footer/email_normal@2x.png';
import item1_hover from '../assets/image/Footer/twitter_hover@2x.png';
import item2_hover from '../assets/image/Footer/medium_hover@2x.png';
import item3_hover from '../assets/image/Footer/telegram_hover@2x.png';
import item5_hover from '../assets/image/Footer/gitbook_hover@2x.png';
import item6_hover from '../assets/image/Footer/github_hover@2x.png';
import item7_hover from '../assets/image/Footer/email_hover@2x.png';
import jump from '../assets/header/jump_grey.svg';
import jump_hover from '../assets/header/jump_hover.svg';
import { NavLink, useHistory } from 'react-router-dom';
var FooterDom = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  height: fit-content;\n  background: #000;\n\n  ", "\n  &.builder {\n    position: fixed;\n    bottom: 0;\n    z-index: 666;\n  }\n\n  .container {\n    padding: 0 73rem;\n    height: 72rem;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    ", "\n    .partner {\n      ", "\n      display: flex;\n      justify-content: center;\n      .item {\n      }\n\n      .item1 {\n      }\n    }\n  }\n\n  .bottom {\n    height: 72rem;\n    width: 100%;\n    border-top: 1rem solid #2E2E2E;\n    font-family: Poppins-Regular;\n    font-weight: 400;\n    font-size: 16rem;\n    color: #A2A2A2;\n    font-style: normal;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    ", "\n  }\n"], ["\n  width: 100%;\n  height: fit-content;\n  background: #000;\n\n  ", "\n  &.builder {\n    position: fixed;\n    bottom: 0;\n    z-index: 666;\n  }\n\n  .container {\n    padding: 0 73rem;\n    height: 72rem;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    ", "\n    .partner {\n      ", "\n      display: flex;\n      justify-content: center;\n      .item {\n      }\n\n      .item1 {\n      }\n    }\n  }\n\n  .bottom {\n    height: 72rem;\n    width: 100%;\n    border-top: 1rem solid #2E2E2E;\n    font-family: Poppins-Regular;\n    font-weight: 400;\n    font-size: 16rem;\n    color: #A2A2A2;\n    font-style: normal;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    ", "\n  }\n"
    // const Logo = styled.a`
    //   width: 149rem;
    //   height: 32rem;
    //   background: url(${HeaderLogo}) no-repeat;
    //   background-size: 100% 100%;
    //   cursor: pointer;
    //   ${({theme}) => theme.mediaWidth.upToSmall`
    //       width: 90rem;
    //       height: 24rem;
    //       margin: 0 auto
    //    `}
    // `
])), function (_a) {
    var theme = _a.theme;
    return theme.mediaWidth.upToSmall(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n    height: fit-content;\n  "], ["\n    width: 100%;\n    height: fit-content;\n  "])));
}, function (_a) {
    var theme = _a.theme;
    return theme.mediaWidth.upToSmall(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      padding: 0 16rem;\n      height: 72rem;\n      flex-wrap: wrap;\n    "], ["\n      padding: 0 16rem;\n      height: 72rem;\n      flex-wrap: wrap;\n    "])));
}, function (_a) {
    var theme = _a.theme;
    return theme.mediaWidth.upToSmall(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        width: 100vw\n      "], ["\n        width: 100vw\n      "])));
}, function (_a) {
    var theme = _a.theme;
    return theme.mediaWidth.upToSmall(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        height: 44rem;\n        font-size: 9rem;\n    "], ["\n        height: 44rem;\n        font-size: 9rem;\n    "])));
});
// const Logo = styled.a`
//   width: 149rem;
//   height: 32rem;
//   background: url(${HeaderLogo}) no-repeat;
//   background-size: 100% 100%;
//   cursor: pointer;
//   ${({theme}) => theme.mediaWidth.upToSmall`
//       width: 90rem;
//       height: 24rem;
//       margin: 0 auto
//    `}
// `
var LinkDom = styled.a(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: block;\n  width: 26rem;\n  height: 26rem;\n \n\n  background-image: ", ";\n  background-size: 100% 100%;\n  cursor: pointer;\n\n  &:hover {\n    background-image: ", ";\n    background-size: 100% 100%;\n  }\n\n\n"], ["\n  display: block;\n  width: 26rem;\n  height: 26rem;\n \n\n  background-image: ", ";\n  background-size: 100% 100%;\n  cursor: pointer;\n\n  &:hover {\n    background-image: ", ";\n    background-size: 100% 100%;\n  }\n\n\n"])), function (_a) {
    var bg = _a.bg;
    return bg && "url(".concat(bg, ")");
}, function (_a) {
    var bghover = _a.bghover;
    return bghover && "url(".concat(bghover, ")");
});
export default function MobileFooter(_a) {
    var className = _a.className;
    var history = useHistory();
    var ImgDate = [item1, item2, item3, item5, item6, item7];
    var ImgDate_Hover = [item1_hover, item2_hover, item3_hover, item5_hover, item6_hover, item7_hover];
    var LinkJump = [
        'https://x.com/netx_world',
        'https://medium.com/@netxworld',
        'https://t.me/netx_world',
        // 'https://discord.com/invite/NZaGSQzeeB',
        'https://netx.gitbook.io/netx-docs',
        'https://github.com/netxnetwork',
        'mailto:contact@netx.world'
    ];
    var LinkDomContainer = function (_a) {
        var num = _a.num;
        var imageUrl = ImgDate[num];
        var imageHoverUrl = ImgDate_Hover[num];
        return (_jsx(LinkDom, { className: "item".concat(num, " item"), bg: imageUrl, bghover: imageHoverUrl, href: LinkJump[num], target: '_blank' }));
    };
    var menuItems = [
        {
            title: "NetX",
            items: [
                {
                    name: 'AgentInfo',
                    pathUrl: 'agentinfo',
                },
                {
                    name: 'Technology',
                    pathUrl: 'technology',
                },
                {
                    name: 'Economy',
                    pathUrl: 'economy',
                },
            ]
        },
        {
            title: "Participate",
            items: [
                {
                    name: 'Faucet',
                    link: 'https://www.netx.world/faucet',
                    target: '_self',
                    pathUrl: 'https://www.netx.world/faucet',
                },
                {
                    name: 'Explorer',
                    link: 'https://www.tscscan.io',
                    pathUrl: 'https://www.tscscan.io',
                }
            ]
        },
        {
            title: "About",
            items: [
                {
                    name: 'Medium',
                    link: 'https://medium.com/@netxworld',
                    pathUrl: 'https://medium.com/@netxworld',
                },
                {
                    name: 'Whitepaper',
                    link: 'https://netx.gitbook.io/netx-docs/whitepaper',
                    pathUrl: 'https://netx.gitbook.io/netx-docs/whitepaper',
                }
            ]
        }
    ];
    return (_jsxs(FooterWrapper, { children: [_jsxs(FooterContent, { children: [_jsxs(LogoSection, { children: [_jsx(Logo, { src: HeaderLogo, alt: "NetX Logo", onClick: function () {
                                    history.push('/');
                                } }), _jsx(SocialIcons, { children: [1, 2, 3, 4, 5, 6].map(function (item, index) { return (_jsx(LinkDomContainer, { num: index }, index)); }) })] }), _jsx(MenuSection, { children: menuItems.map(function (menu, index) { return (_jsxs(MenuColumn, { children: [_jsx(MenuTitle, { children: menu.title }), _jsx(MenuItemsDom, { children: menu.items.map(function (item, itemIndex) {
                                        return (_jsx(_Fragment, { children: item.link ? _jsxs(MenuItem, { href: item.pathUrl, target: item.target ? item.target : '_blank', children: [item.name, item.link && item.name !== 'Faucet' && _jsx(PointRight, { className: 'jump' })] }, itemIndex) : item.pathUrl ? _jsx(NavLink, { to: item.pathUrl, children: item.name }, item.name) : _jsx("span", {}) }));
                                    }) })] }, index)); }) })] }), _jsx(Divider, {}), _jsx(Copyright, { children: "Copyright \u00A9 NetX. All Rights Reserved" })] }));
}
var FooterWrapper = styled.footer(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n"], ["\n"])));
var FooterContent = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\nwidth: 100%;\npadding: 45rem 24rem 0 24rem;\n\n"], ["\nwidth: 100%;\npadding: 45rem 24rem 0 24rem;\n\n"])));
var LogoSection = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 100%;\n  padding-bottom: 20rem;\n"], ["\n  width: 100%;\n  padding-bottom: 20rem;\n"])));
var Logo = styled.img(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 65rem;\n height: 30rem;\n  margin-bottom: 32rem;\n  cursor: pointer;\n"], ["\n  width: 65rem;\n height: 30rem;\n  margin-bottom: 32rem;\n  cursor: pointer;\n"])));
var SocialIcons = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
var MenuSection = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\nwidth: 100%; \n"], ["\nwidth: 100%; \n"])));
var PointRight = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: inline-block;\n  width: 16rem;\n  height: 16rem;\n  margin-left: 8rem;\n  background: url(", ");\n  background-size: 100% 100%;\n\n"], ["\n  display: inline-block;\n  width: 16rem;\n  height: 16rem;\n  margin-left: 8rem;\n  background: url(", ");\n  background-size: 100% 100%;\n\n"])), jump);
var MenuColumn = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n\n  >a{\n    color: #a2a2a2;\n  font-size: 16rem;\n  font-weight: 300;\n  margin-bottom: 24rem;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  font-family:Montserrat-Light;\n  &:hover {\n    color: #5585FC;\n    .jump{\n      background: url(", ");\n      background-size: 100% 100%;\n    }\n  }\n  }\n"], ["\n\n  >a{\n    color: #a2a2a2;\n  font-size: 16rem;\n  font-weight: 300;\n  margin-bottom: 24rem;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  font-family:Montserrat-Light;\n  &:hover {\n    color: #5585FC;\n    .jump{\n      background: url(", ");\n      background-size: 100% 100%;\n    }\n  }\n  }\n"])), jump_hover);
var MenuTitle = styled.h4(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\nfont-family:Montserrat-Medium;\nfont-weight: 500;\nfont-size: 16rem;\ncolor: #FFFFFF;\nline-height: 16rem;\nmargin-bottom: 20rem;\npadding-top:20rem;\n"], ["\nfont-family:Montserrat-Medium;\nfont-weight: 500;\nfont-size: 16rem;\ncolor: #FFFFFF;\nline-height: 16rem;\nmargin-bottom: 20rem;\npadding-top:20rem;\n"])));
var MenuItemsDom = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    display: flex;\n    flex-wrap: wrap;\n    >a{\n        width: 50%;\n    color: #a2a2a2;\n  font-size: 14rem;\n  font-weight: 300;\n  margin-bottom: 20rem;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  font-family:Montserrat-Light;\n  &:hover {\n    color: #5585FC;\n    .jump{\n      background: url(", ");\n      background-size: 100% 100%;\n    }\n  }\n  }\n\n"], ["\n    display: flex;\n    flex-wrap: wrap;\n    >a{\n        width: 50%;\n    color: #a2a2a2;\n  font-size: 14rem;\n  font-weight: 300;\n  margin-bottom: 20rem;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  font-family:Montserrat-Light;\n  &:hover {\n    color: #5585FC;\n    .jump{\n      background: url(", ");\n      background-size: 100% 100%;\n    }\n  }\n  }\n\n"])), jump_hover);
var MenuItem = styled.a(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  color: #a2a2a2;\n  font-size: 14rem;\n  font-weight: 300;\n  margin-bottom: 24rem;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  font-family:Montserrat-Light;\n  &:hover {\n    color: #5585FC;\n    .jump{\n      background: url(", ");\n      background-size: 100% 100%;\n    }\n  }\n"], ["\n  color: #a2a2a2;\n  font-size: 14rem;\n  font-weight: 300;\n  margin-bottom: 24rem;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  font-family:Montserrat-Light;\n  &:hover {\n    color: #5585FC;\n    .jump{\n      background: url(", ");\n      background-size: 100% 100%;\n    }\n  }\n"])), jump_hover);
var Divider = styled.hr(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  border: none;\n  border-top: 1rem solid #2e2e2e;\n  margin: 24rem 0;\n"], ["\n  border: none;\n  border-top: 1rem solid #2e2e2e;\n  margin: 24rem 0;\n"])));
var Copyright = styled.p(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  text-align: center;\n  font-size: 10rem;\n  font-weight: 300;\n  height: 58rem;\n  color: #A2A2A2;\n"], ["\n  text-align: center;\n  font-size: 10rem;\n  font-weight: 300;\n  height: 58rem;\n  color: #A2A2A2;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19;
